<template>
  <div class="public">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/public'>公告管理</el-breadcrumb-item>
      <el-breadcrumb-item>公告详情</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- <el-form  label-width="100px" :model='form' ref='form' v-loading='dataListLoading'>
      <el-form-item label="公告编号" prop=''>
        {{form.noticeNumber}}
      </el-form-item>
      <el-form-item label="公告标题" prop='noticeTitle'>
        {{form.noticeTitle}}
      </el-form-item>
      <el-form-item label="公告分类" prop='noticeType'> 
        {{form.typeName}}
            </el-form-item>
      
      <el-form-item label='内容摘要'>{{form.noticeSummary}}
      
      </el-form-item>
      <el-form-item label="跳转链接" >{{form.noticeRedirectUrl}}
      </el-form-item>
      <el-form-item
          label="正文内容"
          prop="noticeContent"
        >
       
          <div v-html='form.noticeContent' ></div>
        </el-form-item>
        <el-form-item label="排序权重" >
          <el-input placeholder="默认为0，权重值越大，服务排序越靠前" v-model='form.orderWeight' class='wd400'></el-input>
        </el-form-item>
        <el-form-item label="浏览次数" >
          <el-input  v-model='form.viewNum' type='number' class='wd400'></el-input>
        </el-form-item>
        <el-form-item label="作者" >
          <el-input  v-model='form.noticeAuther' class='wd400'></el-input>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
                v-model="form.publishTime"
                type="datetime"
                placeholder="选择日期时间"
                format
                value-format='yyyy-MM-DD HH:mm:ss'>
          </el-date-picker>
        </el-form-item>
        
    </el-form> -->
    <div class="public-info">
      <div class="title">分类:{{form.typeName}}</div>
      <div class="title mt10" @click='jump'>标题:{{form.noticeTitle}}</div>
      <div class="name mt10">{{form.noticeAuther}} {{form.publishTime}} <div class="times " style='margin-left:20%'>浏览次数：{{form.viewNum}}</div></div>
      <div class="name mt10">摘要</div>
      <div class='mt10 times'>{{form.noticeSummary}}</div>
      <div class="name mt10 ">正文内容</div>
      <div class='mt10' v-html='form.noticeContent'></div>

    </div>
  </div>
</template>
<script>
import TinyMce from "@/components/tiny-mce";
export default {
  name:'publicInfo',
  components:{
    TinyMce
  },
  data(){
    return {
      edit:false,
      form:{
        noticeTitle:'',
        noticeType:'',
        noticeSummary:'',
        noticeRedirectUrl:'',
        noticeContent:'',
        noticeAuther:'',
        orderWeight:'',
        publishTime:'',
        viewNum:'',
      },
      diseasesList:[],
      typeList:[],
      dataRules:{
        noticeTitle: [{ required: true, message: "公告标题不能为空", trigger: "blur" }],
        noticeType: [{ required: true, message: '请选择公告分类', trigger: 'change' }],
        // workEnertyType: [{ required: true, message: "请选择宣教类型", trigger: "change" }],
        noticeContent: [{ required: true, message: "正文内容不能为空", trigger: "blur" }],
        noticeAuther: [{ required: true, message: "作者不能为空", trigger: "blur" }],
      },
      dataListLoading:false,

    }
  },
  async mounted() {
    // this.now = new Date() 
    // console.log(moment().format("YYYY-MM-DD HH:mm:ss"))
    await this.getTypeList()

    // this.form.publishTime = moment().format("YYYY-MM-DD HH:mm:ss")
    let query = this.$route.query
    if(query.id){
      this.edit = true
      this.dataListLoading = true
      await this.init(query.id)
    }
  },
  methods: {
    back(){
      this.$router.back()
    },
    async init(id){
      const {data:res} = await this.$httpAes({
        url:this.$httpAes.adornUrl('/notice/getNotice'),
        method:"post",
        params:{stringParam1:id}
      })
      if(res.status){
        // res.data.forEach(v=>{
          this.typeList.forEach(v=>{
            if(v.value==res.data.noticeType){
               res.data.typeName = v.name
            
          }})
        // })
        this.form = res.data
        this.dataListLoading = false
      }

    },
    jump(){
      console.log(this.form.noticeRedirectUrl,'this.form.noticeRedirectUrl');
      if(this.form.noticeRedirectUrl){
        window.location.href=this.form.noticeRedirectUrl
        
      }
    },
    async getTypeList(){
      const {data:res} = await this.$httpAes({
        url:this.$httpAes.adornUrl('/dict/list'),
        method:'post',
        data:{
          stringParam1:'公告分类'
        }
      })
      // console.log(res,'res')
      this.typeList=res.data
    },
    
  },
}
</script>
<style lang="scss" scoped>
.public-info{
  .title{
    font-size: 24px;
    font-weight: bold;
    height: 30px;
    left: 30px;
  }
  .name{
    display: flex;
    font-size: 16px;
    color: #999;
    .times{
      color: #333;
      
    }
  }
  .mt10{
    margin-top: 10px;
  }
}
</style>